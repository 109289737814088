import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 통신 인프라 구축 위해 맞손… Full MVNO 사업자로서 구체적 밑그림 위한 행보",
  "| 고착화된 업계 내 신선한 혁신의 바람을 불어넣길 희망",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/43/43_img1.png" style={{ width: "300px", margin: "auto" }} alt="" />
      </div>
      <div className="desc">&lt;(위)스테이지파이브-네이버클라우드 업무협약식&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브는 지난 11월 1일 역삼 소재 네이버클라우드 강남 오피스에서 네이버클라우드와 'Full MVNO 코어망, 인프라 개발 및
      사업화' 협력을 위한 업무협약을 체결했다고 밝혔다.
      <br />
      <br />
      이번 협약을 통해 양사는 △Full MVNO 코어망 및 인프라 기술 개발 △Full MVNO 기반의 차별화 서비스 개발과 사업화를 위해 긴밀하게
      협력할 방침이다.
      <br />
      <br />
      네이버클라우드는 ‘이음5G’ 제1호 사업자로서 5G와 클라우드, 로보틱스, 디지털트윈 등의 기술을 네이버 제2사옥인 1784에 접목한
      노하우와 글로벌 수준의 클라우드 인프라를 제공한다는 계획이다. 클라우드 인프라 상의 Full MVNO 코어망 구축은 글로벌에서도 드문
      사례로, 통신과 클라우드 접목의 새로운 레퍼런스를 만든다는 계획이다.
      <br />
      <br />
      스테이지파이브는 기술 혁신을 통해 쉽고 간편한 통신 서비스 경험이 가능한 플랫폼 ‘핀다이렉트’를 운영하는 기업이다. 동사는 이번
      협약을 통해 기존 기술 투자 기조를 이어, Full MVNO를 위한 플랫폼 구축을 위해 본격 채비할 예정이다.
      <br />
      <br />
      Full MVNO란 단순 요금제 재판매 중심의 기존 알뜰폰 사업자와 달리 자체 통신망과 관련 기술을 보유하고, 직접 요금제 개발이
      가능한 사업자를 말한다.
      <br />
      <br />
      지난 7월 과학기술정보통신부 통신시장 경쟁촉진방안 브리핑에서도 확인할 수 있듯 Full MVNO 육성은 알뜰폰 시장 활성화를 위한
      국가적 과제이기도 하다. 과기정통부는 해당 브리핑에서 Full MVNO 등 경쟁력 있는 알뜰폰 사업자의 성장을 지원하기 위해 도매제공
      의무제도를 상설화하여 알뜰폰이 지속 가능할 수 있는 안정적 제도 기반을 마련하는 한편, 자체 설비를 보유한 사업자가 데이터를
      대량 선구매 할 경우 추가 할인을 받을 수 있는 방안을 도입하겠다고 밝힌 바 있다.
      <br />
      <br />
      스테이지파이브 관계자는 "해당 업무협약을 통한 혁신으로 고착화된 업계 내 신선한 바람을 불어넣고 싶다"며, “양사간 긴밀한
      협조를 통해 Full MVNO 사업자가 되기 위한 준비를 차분히 해나가는 것이 목표“이라고 말했다.
      <br />
    </p>
  </div>
)

const press43 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 11월 8일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 네이버클라우드와 업무협약 체결"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press43
